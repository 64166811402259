// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import kebabCase from 'lodash/kebabCase'
import startsWith from 'lodash/startsWith'
import isNull from 'lodash/isNull'

import find from 'lodash/find'
import isUndefined from 'lodash/isUndefined'
import classNames from 'classnames'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { compiler } from 'markdown-to-jsx'

import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import './style.less'

import GridGallery from '../grid-gallery'
import '../grid-gallery/style.less'

import Slider from '../slider'
import '../slider/style.less'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import ArticleSchema from '../schema/article-schema'

import indexImage from '../../images/banners/launch.jpg'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const pageQuery = graphql`
  query PageQuery($position: Float, $positionP1: Float) {
    allResources(filter: { position: { gte: $position, lt: $positionP1 } }) {
      edges {
        node {
          title
          position
          routeSlug
          contentBlocks {
            type
            subType
            content
            contents
            resource {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            resources {
              resource {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            contentBlocks {
              type
              subType
              content
              contents
            }
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => {
  const {
    data: {
      allResources: { edges },
    },
    pageContext: { position },
  } = props

  const nodes = map(edges, 'node')
  const ordered = orderBy(nodes, ['position', 'asc'])
  const match = filter(nodes, ['position', position])

  const chapterTitle = match[0].title
  const chapterSlug = match[0].routeSlug

  const contentNode = find(ordered, (node) => node.contentBlocks.length > 0)
  const contentNodeContentBlocks = contentNode.contentBlocks
  const contentNodeAbstractBlockForChapter = find(
    contentNodeContentBlocks,
    (block) =>
      block.type === 'sub-text' ||
      block.type === 'text' ||
      block.type === 'block'
  )
  let chapterAbstract = ''
  if (contentNodeAbstractBlockForChapter.type === 'sub-text') {
    chapterAbstract = contentNodeAbstractBlockForChapter.content
  }
  if (contentNodeAbstractBlockForChapter.type === 'text') {
    chapterAbstract = contentNodeAbstractBlockForChapter.content
  }
  if (contentNodeAbstractBlockForChapter.type === 'block') {
    chapterAbstract =
      contentNodeAbstractBlockForChapter.contentBlocks[0].content
  }

  const contentNodeImageBlockForChapter = find(
    contentNodeContentBlocks,
    (block) => block.type === 'carousel' || block.type === 'image'
  )

  let chapterImage = indexImage
  if (isUndefined(contentNodeImageBlockForChapter) === false) {
    if (contentNodeImageBlockForChapter.type === 'carousel') {
      if (contentNodeImageBlockForChapter.resources.length > 0) {
        chapterImage =
          contentNodeImageBlockForChapter.resources[0].resource.childImageSharp
            .gatsbyImageData.images.fallback.src
      }
    } else if (contentNodeImageBlockForChapter.type === 'image') {
      chapterImage =
        contentNodeImageBlockForChapter.resource.childImageSharp.gatsbyImageData
          .images.fallback.src
    }
  }

  const pageSchema = {
    title: chapterTitle,
    slug: chapterSlug,
    abstract: chapterAbstract,
    breadcrumbs: [
      { title: 'Cover Page', slug: '' },
      { title: chapterTitle, slug: chapterSlug },
    ],
    cover: chapterImage,
  }

  return (
    <StandardPageWrapper
      className="book-page"
      pageSchema={pageSchema}
      {...props}
    >
      <div
        className="container"
        style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
      >
        {map(ordered, ({ title, routeSlug, contentBlocks }, index) => {
          let sectionTitle = chapterTitle
          let sectionSlug = chapterSlug
          let sectionAbstract = chapterAbstract
          let sectionImage = chapterImage

          if (index !== 0) {
            sectionTitle = `${chapterTitle}; Section ${index}. ${title}`
            sectionSlug = `${chapterSlug}#${routeSlug}`

            const contentNodeAbstractBlockForSection = find(
              contentBlocks,
              (block) =>
                block.type === 'sub-text' ||
                block.type === 'text' ||
                block.type === 'block'
            )
            if (contentNodeAbstractBlockForSection.type === 'sub-text') {
              sectionAbstract = contentNodeAbstractBlockForSection.content
            }
            if (contentNodeAbstractBlockForSection.type === 'text') {
              sectionAbstract = contentNodeAbstractBlockForSection.content
            }
            if (contentNodeAbstractBlockForSection.type === 'block') {
              sectionAbstract =
                contentNodeAbstractBlockForSection.contentBlocks[0].content
            }

            const contentNodeImageBlockForSection = find(
              contentBlocks,
              (block) => block.type === 'carousel' || block.type === 'image'
            )

            if (isUndefined(contentNodeImageBlockForSection) === false) {
              if (contentNodeImageBlockForSection.type === 'carousel') {
                if (contentNodeImageBlockForSection.resources.length > 0) {
                  sectionImage =
                    contentNodeImageBlockForSection.resources[0].resource
                      .childImageSharp.gatsbyImageData.images.fallback.src
                }
              } else if (contentNodeImageBlockForSection.type === 'image') {
                sectionImage =
                  contentNodeImageBlockForSection.resource.childImageSharp
                    .gatsbyImageData.images.fallback.src
              }
            }
          }

          const articleSchemaData = {
            name: sectionTitle,
            slug: sectionSlug,
            articleBody: sectionAbstract,
            breadcrumbs: [
              { title: 'Cover Page', slug: '' },
              { title: chapterTitle, slug: chapterSlug },
              { title: sectionTitle, slug: sectionSlug },
            ],
            cover: sectionImage,
          }

          return (
            <Fragment>
              <ArticleSchema data={articleSchemaData} />
              {/* <ChapterSchema data={chapterSchemaData} /> */}
              {index === 0 && <h1 id={kebabCase(title)}>{title}</h1>}
              {index !== 0 && (
                <h2 id={kebabCase(title)}>
                  {index}. {title}
                </h2>
              )}
              {map(contentBlocks, (block) => {
                const {
                  type,
                  subType = '',
                  content,
                  resources,
                  resource,
                  contentBlocks: childContentBlocks,
                  caption,
                  contents = [],
                } = block
                let returnThis = <Fragment />

                if (type === 'space') {
                  returnThis = <br />
                }

                if (type === 'block') {
                  returnThis = (
                    <div className={classNames('block as-paragraph', subType)}>
                      {map(childContentBlocks, (childBlock) => {
                        const {
                          type: childType,
                          subType: childSubType = false,
                          content: childContent,
                          contents: childContents = [],
                        } = childBlock

                        let returnThat = <Fragment />

                        if (childType === 'list') {
                          returnThat = (
                            <p className={classNames(childSubType)}>
                              {map(childContents, (item) => (
                                <Fragment>
                                  {compiler(item, { wrapper: null })}
                                  <br />
                                </Fragment>
                              ))}
                            </p>
                          )
                        }

                        if (childType === 'text') {
                          returnThat = (
                            <p className={classNames(childSubType)}>
                              {compiler(childContent, { wrapper: null })}
                            </p>
                          )
                        }

                        if (childType === 'title') {
                          returnThat = <h3>{childContent}</h3>
                        }

                        return returnThat
                      })}
                    </div>
                  )
                }

                if (type === 'gallery') {
                  returnThis = <Slider resources={resources} />
                }

                if (type === 'text') {
                  returnThis = <p>{compiler(content, { wrapper: null })}</p>
                }

                if (type === 'list') {
                  returnThis = (
                    <p className={classNames(subType)}>
                      {map(contents, (item) => (
                        <Fragment>
                          {compiler(item, { wrapper: null })}
                          <br />
                        </Fragment>
                      ))}
                    </p>
                  )
                }

                if (type === 'grid-gallery') {
                  const gridGalleruImages = []
                  map(resources, ({ resource }) => {
                    gridGalleruImages.push({
                      data: resource,
                      height: resource.childImageSharp.gatsbyImageData.height,
                      width: resource.childImageSharp.gatsbyImageData.width,
                    })
                  })

                  returnThis = <GridGallery images={gridGalleruImages} />
                }

                if (type === 'title') {
                  returnThis = <h3>{content}</h3>
                }

                if (type === 'sub-title') {
                  returnThis = <h4>{content}</h4>
                }

                if (type === 'image') {
                  const image = getImage(resource)
                  const isPortrait = subType === 'portrait' ? 'portrait' : ''

                  if (isNull(caption) === false) {
                    returnThis = (
                      <div className="with-caption as-paragraph">
                        <GatsbyImage
                          className={`${isPortrait}`}
                          image={image}
                        />
                        <p className="caption">{caption}</p>
                      </div>
                    )
                  } else {
                    returnThis = (
                      <GatsbyImage
                        className={`cover as-paragraph ${isPortrait}`}
                        image={image}
                      />
                    )
                  }
                }

                return returnThis
              })}
            </Fragment>
          )
        })}
      </div>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
